<template>
  <div class="page" :class="$store.state.base.lang">
    <app-header :navIndex="1"></app-header>
    <banner :data="banner"></banner>
    <section
      class="content content-1"
      :style="{
        backgroundImage: isMobile
          ? `url(${$t('drink.content1.bg')})`
          : `url(${$t('drink.content1.bg')})`,
      }"
    >
      <div>
        <div class="content-title">
          {{ $t('drink.content1.title') }}
        </div>
        <div class="content-text">
          {{ $t('drink.content1.content') }}
        </div>
      </div>
    </section>
    <section
      class="content content-2"
      :style="{ backgroundImage: `url(${$t('drink.content2.bg')})` }"
    >
      <div class="content-container">
        <div class="content-title">
          {{ $t('drink.content2.title') }}
        </div>
        <div class="content-text" v-html="$t('drink.content2.content')"></div>
        <div class="content-desc">
          {{ $t('drink.content2.desc') }}
        </div>
      </div>
    </section>
    <section class="content content-3">
      <div class="content-title">{{ $t('drink.content3.title') }}</div>
      <div class="content-text">{{ $t('drink.content3.content') }}</div>
      <div class="content-list">
        <div
          v-for="(item, index) in $t('drink.content3.list')"
          :key="index"
          class="content-item"
        >
          <div class="content-item-icon"><img :src="item.icon" alt="" /></div>
          <div>
            <div class="content-item-title">{{ item.title }}</div>
            <div class="content-item-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="content content-4"
      :style="{ backgroundImage: `url(${$t('drink.content4.bg')})` }"
    >
      <div class="content-container">
        <div class="content-title">{{ $t('drink.content4.title') }}</div>
        <div class="content-text">{{ $t('drink.content4.content') }}</div>
      </div>
    </section>
    <section
      class="content content-5"
      :style="{ backgroundImage: `url(${$t('drink.content5.bg')})` }"
    >
      <div class="content-container">
        <div class="content-icon">
          <img :src="$t('drink.content5.icon1')" alt="" />
          <img :src="$t('drink.content5.icon2')" alt="" />
        </div>
        <div class="content-title">{{ $t('drink.content5.title') }}</div>
        <div class="content-text" v-html="$t('drink.content5.content')"></div>
      </div>
    </section>
    <section
      class="content content-6"
      :style="{ backgroundImage: `url(${$t('drink.content6.bg')})` }"
    >
      <div class="content-container">
        <div class="content-title">{{ $t('drink.content6.title') }}</div>
        <div class="content-text">{{ $t('drink.content6.content') }}</div>
        <div class="content-list">
          <div
            v-for="(item, index) in $t('drink.content6.list')"
            :key="index"
            class="content-item"
          >
            {{ item }}
          </div>
        </div>
        <div class="content-text">{{ $t('drink.content6.last') }}</div>
      </div>
    </section>

    <section class="content content-7">
      <div class="content-container">
        <div class="content-title">{{ $t('drink.content7.title') }}</div>
        <div class="content-text">{{ $t('drink.content7.content') }}</div>
      </div>
      <div class="btn">
        <a
          class="btn-link"
          href="https://www.suntory.co.jp/arp/"
          target="_blank"
          >{{ $t('drink.btn') }}</a
        >
        <img
          src="https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/right.png"
          class="icon-right"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Banner from '../../components/base/banner';
import AppHeader from '../../components/core/header/index';
export default {
  components: {
    AppHeader,
    Banner,
  },
  data() {
    return {
      banner: {
        imgUrl: 'company/drink/banner.jpg',
        title: this.$i18n.t('drink.banner.title'),
        enTitle: '',
      },
    };
  },
  mounted() {},
  methods: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
}

.content {
  margin: 0 7.34vw 2.6vw;
  background-size: cover;
  background-position: center;
}

.content-title {
  font-weight: 500;
  font-size: 2.19vw;
  margin-bottom: 1.56vw;
}

.content-text {
  font-size: 1.09vw;
  text-align: justify;
}

.content-1 {
  display: flex;
  align-items: center;
  padding: 1.04vw 4.17vw;
  margin-top: 3.85vw;
  margin-bottom: 2.6vw;
  height: 35.42vw;
  color: #fff;
  .content-text {
    @extend .content-text;
    max-width: 37.5vw;
  }
}

.content-2 {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 2.6vw 4.17vw;
  margin-bottom: 2.6vw;
  color: #fff;
  min-height: 35.42vw;
  .content-container {
    max-width: 44.48vw;
  }

  .content-desc {
    color: #d0d0d0;
    font-size: 0.83vw;
    margin-top: 1.56vw;
  }
}

.content-3 {
  padding-top: 1.04vw;
  margin-bottom: 2.6vw;
  .content-title {
    @extend .content-title;
    color: #191919;
    margin-bottom: 0.52vw;
  }
  .content-text {
    @extend .content-text;
    color: #191919;
    // max-width: 42.55vw;
    margin-bottom: 2.08vw;
  }
  .content-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.94vw;
    color: #fff;

    .content-item {
      padding: 2.6vw 1.56vw 1.04vw;
      flex: 1;
      background: #b78e55;
      min-height: 27.92vw;
      .content-item-icon {
        text-align: center;
        margin-bottom: 2.6vw;
        img {
          height: 12.5vw;
          object-fit: cover;
        }
      }
    }
  }

  .content-item-title {
    @extend .content-text;
    color: #fff;
    margin-bottom: 1.46vw;
  }
  .content-item-content {
    @extend .content-text;
    color: #fff;
  }
}

.content-4 {
  min-height: 35.42vw;
  padding-top: 12.5vw;
  display: flex;
  align-items: center;
  padding-left: 5.21vw;
  padding-bottom: 5.21vw;
  background-position: top center;
  .content-container {
    max-width: 35.21vw;
    color: #fff;
  }
}

.content-5 {
  display: flex;
  align-items: center;
  justify-content: end;
  min-height: 35.42vw;
  padding-right: 3.49vw;
  padding-bottom: 2.6vw;
  padding-top: 2.6vw;
  .content-container {
    max-width: 43.59vw;
    color: #000;
  }
  .content-icon {
    display: flex;
    gap: 1.41vw;
    margin-bottom: 1.56vw;
    img {
      width: 7.08vw;
      height: 7.08vw;
    }
  }
  .content-title {
    margin-bottom: 1.04vw;
  }
}

.content-6 {
  display: flex;
  align-items: center;
  min-height: 35.42vw;
  color: #fff;
  padding-left: 6.98vw;
  padding-top: 5.21vw;
  padding-bottom: 5.21vw;
  padding-right: 5.21vw;
  .content-container {
    max-width: 33.96vw;
  }
  .content-list {
    color: #fff;
    margin-top: 1.56vw;
    font-size: 1.09vw;
    margin-bottom: 1.56vw;
    text-align: justify;
    .content-item {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -1.77vw;
        top: 0.26vw;
        width: 1.04vw;
        height: 1.3vw;
        background: url('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/cup.png')
          no-repeat center center / contain;
      }
    }
  }
}

.content-7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b78e55;
  color: #fff;
  padding: 2.86vw 4.17vw;
  .content-container {
    max-width: 31.88vw;
  }
  .btn {
    cursor: pointer;
    min-width: 16.04vw;
    padding: 0 1.04vw;
    height: 2.6vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 2.6vw;
    box-shadow: 0px 0px 0.52vw 0px rgba(0, 0, 0, 0.1);
  }
  .btn-link {
    color: #b78e55;
    font-weight: 500;
    font-size: 1.09vw;
    &:hover {
      opacity: 0.8;
    }
  }
  .icon-right {
    width: 1.25vw;
    height: 1.25vw;
  }
}

@media (max-width: $screen-md) {
  .content {
    margin: 2.6vw 3.34vw 2.6vw;
  }
}

@media (max-width: $screen-sm) {
  .content {
    margin: 40px;
  }
  .content-title {
    font-size: 28px;
  }
  .content-text {
    font-size: 16px;
  }
  .content-1 {
    height: 500px;
    .content-text {
      max-width: 500px;
    }
  }
  .content-3 {
    .content-list {
      gap: 15px;
      .content-item {
        min-height: 400px;
        .content-item-icon {
          img {
            height: 150px;
          }
        }
      }
    }
  }
  .content-4 {
    min-height: 500px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
    padding-bottom: 50px;
    .content-container {
      max-width: 500px;
    }
  }
  .content-5 {
    min-height: 500px;
    padding: 50px;
    .content-container {
      max-width: 500px;
      .content-icon {
        gap: 15px;
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .content-6 {
    padding: 50px 50px 50px 80px;
    .content-container {
      max-width: 500px;
      .content-list {
        font-size: 16px;
      }
    }
  }
  .content-7 {
    align-items: start;
    padding: 50px;
    justify-content: start;
    flex-direction: column;

    .btn {
      margin-top: 30px;
      min-width: 250px;
      .btn-link {
        font-size: 16px;
      }
    }
  }
  .content {
    margin: 10px 0;
  }
  .content-title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .content-text {
    font-size: 12px;
    text-align: justify;
  }
  .content-1 {
    display: block;
    background-image: url('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/bg1_m.jpg') !important;
    padding: 42px 26px;
  }
  .content-2 {
    display: block;
    padding: 42px 26px;
    min-height: 80vh;
    background-image: url('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/bg2_m.jpg') !important;

    .content-desc {
      font-size: 12px;
      margin-top: 20px;
    }
    .content-container {
      max-width: 100%;
    }
  }
  .content-3 {
    padding: 0;
    margin-top: 50px;
    .content-title {
      padding: 0 30px;
    }
    .content-text {
      padding: 0 30px;
      margin-bottom: 20px;
      max-width: 100%;
    }
    .content-list {
      display: block;
      padding: 0 10px;
      .content-item {
        display: flex;
        align-items: center;
        min-height: 148px;
        padding: 24px;
        margin-bottom: 10px;
        .content-item-title,
        .content-item-content {
          padding: 0;
          margin: 0;
        }
        .content-item-title {
          margin-bottom: 10px;
        }

        .content-item-icon {
          margin-bottom: 0;
          margin-right: 20px;
          img {
            width: 58px;
            height: 58px;
          }
        }
      }
    }
  }
  .content-4 {
    margin-top: 50px;
    padding-top: 100px;
    padding-bottom: 40px;
    padding-left: 32px;
    padding-right: 32px;
    min-height: 375px;
    background-position: left;
    background-image: url('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/bg4_m.png') !important;
    .content-container {
      max-width: 205px;
    }
  }
  .content-5 {
    display: block;
    padding: 40px 36px;
    min-height: 635px;
    background-image: url('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/bg5_m.jpg') !important;
    .content-container {
      max-width: auto;
      .content-title {
        margin-bottom: 15px;
      }
      .content-icon {
        margin-bottom: 20px;
        img {
          width: 68px;
          height: 68px;
        }
      }
    }
  }
  .content-6 {
    display: block;
    padding: 34px 28px 34px 46px;
    min-height: 635px;
    background-image: url('https://website-asc.oss-cn-shanghai.aliyuncs.com/company/drink/bg6_m.jpg') !important;
    .content-container {
      max-width: auto;
      .content-list {
        font-size: 12px;
        margin-top: 14px;
        margin-bottom: 14px;
        .content-item {
          &::before {
            left: -18px;
            top: 3px;
            width: 10px;
            height: 15px;
          }
        }
      }
    }
  }
  .content-7 {
    padding: 25px 35px;
    .content-container {
      max-width: 100%;
      .content-title {
        margin-bottom: 12px;
      }
      .content-text {
        margin-bottom: 20;
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      min-width: 172px;
      height: 34px;
      padding: 0 12px;
      margin-top: 20px;
      .btn-link {
        font-size: 12px;
      }
      .icon-right {
        width: 14px;
        height: 14px;
      }
    }
  }
  .page {
    &.en {
      .content-4 {
        padding-top: 120px;
        padding-left: 20px;
        padding-right: 20px;
        background-position: center;
        .content-container {
          max-width: 220px;
        }
      }
    }
  }
}
</style>
